import React, { useEffect, useState } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import Homepage3Dark from "./pages/Homepage3Dark";
import Works from "./components/sections/sectionworks";
import Cert from "./components/sections/sectioncertifications";
import "./App.scss";
import { createClient } from "@supabase/supabase-js";
import UAParser from "ua-parser-js"; // Import UA Parser

const supabaseUrl = "https://mepvuduwzwnuvebwoenn.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1lcHZ1ZHV3endudXZlYndvZW5uIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDE1NTYwNzcsImV4cCI6MjA1NzEzMjA3N30.U0UJcoU8U87a0ecD0JXqJ90VSRBKg7BLcF0nAUjVkEM";

export const supabase = createClient(supabaseUrl, supabaseKey);

function App() {
  const [ip, setIp] = useState("");

  useEffect(() => {
    const fetchAndLogIp = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();

        setIp(data.ip);
        await fetchIPDetails(data.ip);
      } catch (error) {}
    };

    fetchAndLogIp();
  }, []);

  const fetchIPDetails = async (ip) => {
    let details = {};
  
    try {
      // Fetch details from ipapi.co
      const response = await fetch(`https://ipapi.co/${ip}/json/`);
  
      if (!response.ok) {
        throw new Error(`API error: ${response.status} ${response.statusText}`);
      }
  
      details = await response.json();
  
      // Parse User-Agent for device, browser, and OS info
      const parser = new UAParser();
      const userAgentDetails = parser.getResult();
      const browserName = userAgentDetails.browser.name || "Unknown";
      const browserVersion = userAgentDetails.browser.version || "Unknown";
      const osName = userAgentDetails.os.name || "Unknown";
      const osVersion = userAgentDetails.os.version || "Unknown";
      const deviceType = userAgentDetails.device.type || "Desktop";
  
      // Define data to insert
      const logEntry = {
        ip: details.ip,
        network: details.network || "",
        version: details.version || "",
        city: details.city || "",
        region: details.region || "",
        region_code: details.region_code || "",
        country: details.country || "",
        country_name: details.country_name || "",
        postal: details.postal || "",
        latitude: details.latitude || null,
        longitude: details.longitude || null,
        timezone: details.timezone || "",
        asn: details.asn || "",
        org: details.org || "",
        user_agent: navigator.userAgent,
        platform: navigator.platform,
        device_type: deviceType,
        browser_name: browserName,
        browser_version: browserVersion,
        os_name: osName,
        os_version: osVersion,
      };
  
      // Retry Supabase insert up to 3 times
      await retrySupabaseInsert("logger_2", logEntry);
    } catch (error) {
      // Prepare minimal fallback log entry
      const errorEntry = {
        ip: details.ip || "",
        network: error.message || "",
        user_agent: navigator.userAgent,
        platform: navigator.platform,
      };
  
      await retrySupabaseInsert("logger_2", errorEntry);
      console.error("Logging failed:", error);
    }
  };
  
  const retrySupabaseInsert = async (table, entry, maxRetries = 3) => {
    let attempt = 0;
    let lastError;
  
    while (attempt < maxRetries) {
      const { error } = await supabase.from(table).insert([entry]);
      if (!error) return; // Success
      lastError = error;
      attempt++;
      await new Promise((resolve) => setTimeout(resolve, 500 * attempt)); // Simple backoff
    }
  
    // Final failure after retries – log that this insert failed
    console.error(`Supabase insert failed after ${maxRetries} attempts`, lastError);
  
    // Attempt to log that the original insert failed
    const fallbackErrorEntry = {
      ip: entry.ip || "",
      network: "Final Supabase insert failed after retries",
      user_agent: navigator.userAgent,
      platform: navigator.platform,
    };
  
    await supabase.from(table).insert([fallbackErrorEntry]);
  };
  
  return (
    <HashRouter basename="/">
      <Switch>
        <Route path="/" exact>
          <Homepage3Dark />
        </Route>
        <Route path="/moresoftware" exact></Route>
        <Route path="/moreproject" exact>
          <Works />
        </Route>
        <Route path="/morecertifications" exact>
          <Cert />
        </Route>
      </Switch>
    </HashRouter>
  );
}

export default App;
